// Your custom variables

$dark: #0F1C34;
$dark-gray: #242A34;
$gray: #666F80;
$light-gray: #96A0B3;
$very-light-gray: #DADEE6;
$silver: #F2F4F7;
$teal: #1D9FB3;
$teal-bright: #35B8CC;
$orange: #D68556;

$red: #B84242;
$primary-color: $teal;
$primary-color-bright: $teal-bright;
$secondary-color: $orange;


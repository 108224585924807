// Your custom styles

body {
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  color: $dark;
  background-color: $silver;
}
.secondary-content {
  margin-top: 4.5rem;
}
@media (max-width: 767.98px) {
  .secondary-content {
    margin-top: 3.5rem;
  }
}
.banner-bg {
  background: url(../img/bg.jpg);
  background-size: cover;
  background-position: center center;
  position: relative;
  color: #fff;
  height: 42.5rem;
  padding-top: 5rem;
}
@media (max-width: 575px) {
  .banner-bg {
    height: auto;
    padding-bottom: 5rem;
    padding-top: 7rem;
  }
}
@media (max-width: 767.98px) {
  .ithermo-banner {
    padding: 3.5rem 0;
    text-align: center;
  }
}

.footer {
  background-color: $dark;
  color: #fff;
  padding: 3rem 0;
}
.overlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba($dark-gray, 0.4);
}
@media (max-width: 575px) {
  .overlay {
  }
}
.overlay.x-light {
  background: rgba($dark, 0.05);
}
.wrapper.light {
  background-color: #fff;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

hr {
  margin-top: 2rem;
  border-top: 1px solid $light-gray;
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}

.error-occured {
  box-shadow: inset 0 0 0 1px $red;
  background: rgba($red, 0.1);
  padding-left: 10px;
}

.wrapper {
overflow-wrap: break-word;
}
.lh3 {
line-height: 3;
}
.tagline {
	display: inline-block;
	font-size: 1.5rem;
	line-height: 2;
	font-family: 'Comfortaa';
}

.bold {
font-weight: 700;
}

.small, small {
    font-size: .875rem;
	line-height: get-line-height(14px, 24px);
}

.xsmall {
    font-size: .75rem;
	line-height: get-line-height(12px, 20px);
}

.link {
	font-weight: 700;
	color: $primary-color;
	color: $dark;
}

a.link:hover {
	color: $primary-color;
}

.answer {
font-weight: 700;
}

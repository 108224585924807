.close {
  color: $gray;
  opacity: 1;
  span {
    display: none;
  }
  &:after {
    content: url(../img/close.svg);
  }
}
.circle-wrapper {
  cursor: pointer;
  position: relative;
  display: inline-block;
  border-radius: 50%;
  background-color: #fff;
  width: 6rem;
  height: 6rem;
  text-align: center;
  line-height: 3;
  font-size: 1.5rem;
  vertical-align: middle;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: -webkit-flex; /* Safari */
  display: flex;
  -webkit-align-items: center; /* Safari 7.0+ */
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;

  &:hover {
    background-color: rgba(255, 255, 255, 0.7);
    outline: 0px;
  }
  .fa-3x {
    line-height: 2;
  }
  &.dark {
    background-color: $dark;
    &:hover,
    &:active {
      background-color: $primary-color;
      outline: 0px;
    }
  }
  &.med-circle {
    width: 4.5rem;
    height: 4.5rem;

    .fa-lg {
      line-height: 3;
    }
  }
  @media (max-width: 575px) {
    &.med-circle {
      width: 3.5rem;
      height: 3.5rem;

      .fa-lg {
        font-size: 1rem;
        line-height: 3.5;
      }
    }
  }
}
.fa-lg {
  font-size: 1.5rem;
}
.SocialMediaShareButton {
  outline: 0px;
}

#root {
  .btn.p0 {
    padding: 0;
    .inner-button {
      display: block;
      padding: 1rem 1.5rem;
    }
  }

  .btn-outline-blue:not([disabled]):not(.disabled):active,
  .btn-outline-blue:not([disabled]):not(.disabled).active,
  .show > .btn-outline-blue.dropdown-toggle {
    box-shadow: 0 0 0 0;
    background-color: #fff !important;
    border-color: $primary-color !important;
  }
  .btn-outline-blue:not([disabled]):not(.disabled):active:focus,
  .btn-outline-blue:not([disabled]):not(.disabled).active:focus,
  .show > .btn-outline-blue.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 !important;
  }

  .btn-white {
    color: $gray !important;
  }
  .btn {
    box-shadow: 0 0 0 0 !important;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin: 0;
    text-transform: none !important;
    color: #fff;
  }

  .btn-primary {
    background-color: $primary-color !important;
    color: #fff !important;

    &:hover,
    &:focus,
    &:active {
      box-shadow: 0 0 0 0;
      background-color: #ed795f;
    }
    &.blue {
      background-color: $teal !important;
      &:hover,
      &:focus,
      &:active {
        box-shadow: 0 0 0 0;
        background-color: $primary-color-bright !important;
      }
    }
  }
  .btn-outline-blue {
    border: 2px solid $primary-color !important;
    //	box-shadow: inset 0 0 0 2px $off-blue;
    background-color: transparent !important;
    color: $primary-color !important;
  }

  .btn[class*="btn-outline-"] {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }

  .btn-med-width {
    width: 12rem;
  }
  @media (max-width: 575px) {
    .btn-med-width {
      width: auto;
      min-width: 8rem;
    }
  }

  .btn-sm-width {
    width: 8rem;
  }

  .btn.btn-lg {
    font-size: 1.125rem !important;
    padding: 1rem 1.5rem !important;
    line-height: 1.33333333333;
  }

  .btn-primary:not([disabled]):not(.disabled):active:focus,
  .btn-primary:not([disabled]):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle:focus,
  .btn-primary:not([disabled]):not(.disabled):active,
  .btn-primary:not([disabled]):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    box-shadow: 0 0 0 0, 0 0 0 0;
    background-color: $teal !important;
  }

  .btn-outline-blue:hover,
  .btn-outline-blue:focus,
  .btn-outline-blue:active,
  .btn-outline-blue:active:focus,
  .btn-outline-blue.active {
    border-color: $primary-color !important;
    background-color: #fff !important;
    color: $primary-color !important;
  }

  .btn:hover,
  .btn:active,
  .btn:focus {
    box-shadow: 0 0 0 0, 0 0 0 0;
    outline: 0;
  }
}

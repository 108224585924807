.side-nav {
  .nav-link {
    color: $dark;
    line-height: 3;
    padding: 0 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
#sidenav-overlay {
  background-color: rgba($dark, 0.3) !important;
}
.side-nav.side-nav-light {
  background-color: #fff;
  color: $dark !important;
}
.side-nav.main-nav {
  width: auto;
  width: 16rem;
  background-color: #fff;
  box-shadow: 0 0px 0px 0 rgba($dark, 0.3);
  .navbar-nav {
    padding: 1rem 0;
  }
  .navbar-brand {
    display: inline-block;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 1rem;
    font-size: 1.5rem;
    line-height: 2;
    white-space: nowrap;
    color: $dark;
    margin-top: 1rem;
    &:focus,
    &:hover,
    &:active {
      background: transparent;
    }
  }
  a {
    color: $dark;
    line-height: 3;
    height: auto;
    padding: 0 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    &:focus,
    &:hover,
    &:active {
      background: $light-gray;
    }
  }

  .nav-separator:before {
    display: none;
  }
  .nav-separator {
    text-align: center;
    background: $light-gray;
    margin: 1rem;
  }
}
.side-nav.main-nav.side-slide-enter-done {
  box-shadow: 0 4px 25px 0 rgba($dark, 0.3);
}

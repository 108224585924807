ul.large {
  font-size: 1.25rem;
  line-height: get-line-height(20px, 32px);
  padding: 0px;
  list-style-position: inside;
  list-style-type: none;

  li:before {
    content: url(../img/bullet.svg);
    padding-right: 0.75rem;
  }
  li {
    padding: 0.25rem 0;
  }
}

ul {
  padding: 0;
  list-style-position: inside;
}

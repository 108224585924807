/* ==========================================================================
   $BASE-TIME-PICKER
   ========================================================================== */

// stylelint-disable selector-max-class
// stylelint-disable selector-max-combinators
// stylelint-disable selector-max-compound-selectors

/**
 * The list of times.
 */
.picker__list {
  padding: $timepicker-pickerlist-padding;
  margin: 0;
  list-style: none;
}

/**
 * The times on the clock.
 */
.picker__list-item {
  position: relative;
  padding: $timepicker-pickerlistitem-padding;
  margin-bottom: $timepicker-pickerlistitem-mb;
  border-top: $timepicker-pickerlistitem-border-top solid $timepicker-pickerlistitem-border-color;
  border-bottom: $timepicker-pickerlistitem-border-bottom solid $timepicker-pickerlistitem-border-color;
  @extend .white;
  @media (min-height: $timepicker-pickerlistitem-breakpoint) {
    padding: $timepicker-pickerlistitem-media-padding;
  }

  /* Hovered time */
  &:hover {
    z-index: 10;
    cursor: pointer;
    background: $timepicker-pickerlistitem-hover-bg;
    border-color: $timepicker-pickerlistitem-hover-border-color;
    @extend .black;
  }
}

/* Highlighted and hovered/focused time */
.picker__list-item--highlighted {
  z-index: 10;
  border-color: $timepicker-pickerlistitem-highlighted-border-color;
}
.picker__list-item--highlighted:hover,
.picker--focused .picker__list-item--highlighted {
  color: $black;
  cursor: pointer;
  background: $timepicker-pickerlistitem-highlighted-hover-bg;
}

/* Selected and hovered/focused time */
.picker__list-item--selected,
.picker__list-item--selected:hover,
.picker--focused .picker__list-item--selected {
  z-index: 10;
  background: $timepicker-pickerlistitem-selected-hover-bg;
  @extend .white-text;
}

/* Disabled time */
.picker__list-item--disabled,
.picker__list-item--disabled:hover,
.picker--focused .picker__list-item--disabled {
  z-index: auto;
  color: $timepicker-pickerlistitem-disabled-hover-color;
  cursor: default;
  background: $grey-lighten-4;
  border-color: $grey-lighten-4;
  // border-color: $timepicker-pickerlistitem-disabled-hover-color;
}

/**
 * The clear button
 */
.picker--time {
  .picker__button--clear {
    display: block;
    width: $timepicker-pickertime-button-clear-width;
    padding: $timepicker-pickertime-button-clear-padding;
    margin: $timepicker-pickertime-button-clear-mt auto 0;
    font-size: $timepicker-pickertime-button-clear-font-size;
    background: none;
    font-weight: $timepicker-pickertime-button-clear-font-weight;
    color: $timepicker-color-mdb;
    border: 0;
    text-align: center;
    text-transform: uppercase;
    &:hover,
    &:focus {
      color: $black;
      background: $timepicker-pickertime-button-clear-hover-bg;
      cursor: pointer;
      border-color: $timepicker-pickertime-button-clear-hover-border-color;
      @extend .white-text;
      outline: none;
      &::before {
        @extend .white-text;
      }
    }
    &::before {
      top: $timepicker-pickertime-button-clear-before-top;
      font-size: $timepicker-pickertime-button-clear-before-font-size;
      font-weight: $timepicker-pickertime-button-clear-before-font-weight;
      color: $timepicker-color-mdb;
    }
  }
}

/* ==========================================================================
   $DEFAULT-TIME-PICKER
   ========================================================================== */

/**
 * The frame the bounds the time picker.
 */
.picker--time .picker__frame {
  min-width: $timepicker-pickertime-frame-min-width;
  max-width: $timepicker-pickertime-frame-max-width;
}

/**
 * The picker box.
 */
.picker--time .picker__box {
  padding: 0;
  font-size: $timepicker-pickerbox-font-size;
  background: $timepicker-pickerbox-bg;
  @media (min-height: $timepicker-pickerbox-breakpoint) {
    margin-bottom: $timepicker-pickerbox-breakpoint-mb;
  }

}

/*!
 * ClockPicker v0.0.7 for jQuery (http://weareoutman.github.io/clockpicker/)
 * Copyright 2014 Wang Shenwei.
 * Licensed under MIT (https://github.com/weareoutman/clockpicker/blob/gh-pages/LICENSE)
 *
 * Further modified
 * Copyright 2015 Ching Yaw Hao.
 *
 * Bootstrap v3.1.1 (http://getbootstrap.com)
 * Copyright 2011-2014 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
.picker__date-display {
  text-align: center;
  padding-bottom: $timepicker-pickerdate-display-pb;
  background-color: $datepicker-date-bg;
  @extend .white-text;
  margin-bottom: $timepicker-pickerdate-display-mb;
  font-weight: $timepicker-pickerdate-display-font-weight;

  .clockpicker-display {
    display: inline-block;
    vertical-align: middle;
    height: $timepicker-clockpicker-display-height;
    margin: auto;
    padding: $timepicker-clockpicker-display-padding;
    padding-bottom: 0;
    font-size: $timepicker-clockpicker-display-font-size;
    color: $timepicker-clockpicker-display-color;
    .clockpicker-display-column {
      float: left;
      .clockpicker-span-hours.text-primary, 
      .clockpicker-span-minutes.text-primary, 
      #click-am.text-primary, 
      #click-pm.text-primary {
        animation-name: $timepicker-clockpicker-display-animate-name;
        @extend .white-text;
      }
      #click-am, 
      #click-pm {
        cursor: pointer;
      }
    }
    .clockpicker-display-am-pm {
      padding-left: $timepicker-clockpicker-display-am-pm-pl;
      vertical-align: bottom;
      height: $timepicker-clockpicker-display-am-pm-height;
      .clockpicker-span-am-pm {
        display: inline-block;
        font-size: $timepicker-clockpicker-span-am-pm-font-size;
        line-height: $timepicker-clockpicker-span-am-pm-line-height;
        color: $timepicker-clockpicker-span-am-pm-color;
      }
    }
    .clockpicker-span-hours, .clockpicker-span-minutes {
      animation-duration: $timepicker-clockpicker-span-hours-animation-duration;
      cursor: pointer;
      transition: $timepicker-clockpicker-span-hours-transition;
      animation-fill-mode: both;
    }
  }
}
.clockpicker-display {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  height: $timepicker-clockpicker-display-height;
  margin: auto;
  padding: $timepicker-clockpicker-display-padding;
  padding-bottom: 0;
  font-size: $timepicker-clockpicker-display-font-size;
  color: $timepicker-clockpicker-display-color;
  .clockpicker-display-column {
    float: left;
    .clockpicker-span-hours.text-primary, 
    .clockpicker-span-minutes.text-primary, 
    #click-am.text-primary, 
    #click-pm.text-primary {
      animation-name: $timepicker-clockpicker-display-animate-name;
      @extend .white-text;
    }
    #click-am, 
    #click-pm {
      cursor: pointer;
    }
  }
  .clockpicker-display-am-pm {
    padding-left: $timepicker-clockpicker-display-am-pm-pl;
    vertical-align: bottom;
    height: $timepicker-clockpicker-display-am-pm-height;
    .clockpicker-span-am-pm {
      display: inline-block;
      font-size: $timepicker-clockpicker-span-am-pm-font-size;
      line-height: $timepicker-clockpicker-span-am-pm-line-height;
      color: $timepicker-clockpicker-span-am-pm-color;
    }
  }
  .clockpicker-span-hours, 
  .clockpicker-span-minutes {
    animation-duration: $timepicker-clockpicker-span-hours-animation-duration;
    animation-fill-mode: both;
    cursor: pointer;
    @include transition-main($timepicker-clockpicker-span-hours-transition);
  }
}

@include keyframes (pulse){
  from {
    transform: $timepicker-keyframes-transform-pulse;
  }
  50% {
    transform: $timepicker-keyframes-transform-pulse-middle;
  }
  to {
    transform: $timepicker-keyframes-transform-pulse;
  }
}

.clockpicker-moving {
  cursor: move;
}
.clockpicker-plate {
  background-color: $grey-lighten-3;
  border-radius: $timepicker-clockpicker-plate-border-radius;
  width: $timepicker-clockpicker-plate-width;
  height: $timepicker-clockpicker-plate-height;
  overflow: visible;
  position: relative;
  margin: auto;
  margin-top: $timepicker-clockpicker-plate-margin-top;
  /* Disable text selection highlighting. Thanks to Hermanya */
  user-select: none;
  .clockpicker-canvas,
  .clockpicker-dial {
    width: $timepicker-clockpicker-canvas-width;
    height: $timepicker-clockpicker-canvas-height;
    position: absolute;
    left: $timepicker-clockpicker-canvas-left;
    top: $timepicker-clockpicker-canvas-top;
  }
  .clockpicker-dial {
    @include transition-main($timepicker-clockpicker-dial-transition-transform, $timepicker-clockpicker-dial-transition-opacity);
    .clockpicker-tick {
      border-radius: $timepicker-clockpicker-dial-tick-border-radius;
      color: $timepicker-color-mdb;
      line-height: $timepicker-clockpicker-dial-tick-line-height;
      position: absolute;
      width: $timepicker-clockpicker-dial-tick-width;
      height: $timepicker-clockpicker-dial-tick-height;
      text-align: center;
      cursor: pointer;
      background-color: $timepicker-clockpicker-dial-tick-hover-bg;
      transition: $timepicker-clockpicker-dial-tick-transition;
      &.active,
      &:hover {
        background-color: $timepicker-clockpicker-dial-tick-hover-bg-opacity;
      }
    }
  }
  .clockpicker-minutes {
    visibility: hidden;
  }
  .clockpicker-dial-out {
    opacity: 0;
  }
  .clockpicker-hours.clockpicker-dial-out {
    @include transform($timepicker-clockpicker-dial-out-transform);
  }
  .clockpicker-minutes.clockpicker-dial-out {
    @include transform($timepicker-clockpicker-dial-out-transform-minutes);
  }
}

.clockpicker-canvas {
  @include transition-main($timepicker-clockpicker-canvas-transition);
  line {
    stroke: $timepicker-clockpicker-canvas-stroke;
    stroke-width: $timepicker-clockpicker-canvas-stroke-width;
  }
}
.clockpicker-canvas-out {
  opacity: $timepicker-clockpicker-canvas-out-opacity;
}
.clockpicker-canvas-bearing {
  fill: $timepicker-clockpicker-canvas-bearing-fill;
  stroke: none;
}
.clockpicker-canvas-fg {
  fill: $timepicker-clockpicker-canvas-fg-fill;
  stroke: none;
  &.active {
    fill: $timepicker-clockpicker-canvas-fg-active-fill;
  }
}
.clockpicker-canvas-bg {
  fill: $timepicker-clockpicker-canvas-bg-fill;
  stroke: none;
}
.clockpicker-canvas-bg-trans {
  fill: $timepicker-clockpicker-canvas-bg-trans-fill;
}

.clockpicker-am-pm-block{
  width: 100%;
  height: $timepicker-clockpicker-am-pm-block-height;
  margin-top: $timepicker-clockpicker-am-pm-block-mt;
  .clockpicker-button.am-button {
    float: left;
    width: $timepicker-clockpicker-button-am-button-width;
    height: $timepicker-clockpicker-button-am-button-height;
    border: 0;
  }
  .clockpicker-button.pm-button {
    float: right;
    width: $timepicker-clockpicker-button-pm-button-width;
    height: $timepicker-clockpicker-button-pm-button-height;
    border: 0;
  }
}

.btn-floating.btn-flat {
  padding: 0;
  color: $white-base;
  background: $primary-color;
  &:hover {
    box-shadow: none;
  }
  &:hover,
  &:focus {
    background-color: $timepicker-clockpicker-btn-floating-focus-bg;
  }
  &.active {
    background-color: $timepicker-clockpicker-btn-floating-active-bg;
    box-shadow: $z-depth-1-half;
  }
}

.picker__footer {
  width: 100%;
  .clockpicker-button {
    margin: auto;
    margin-top: $timepicker-clockpicker-footer-button-mt;
    text-transform: uppercase;
    background-color: transparent;
    &:focus {
      background-color: transparent;
    }
    &:active {
      background-color: $timepicker-clockpicker-footer-button-active-bg;
    }
  }
}

.darktheme {
  .picker__box {
    background-color: $grey-darken-4;
    .picker__date-display {
      background-color: transparent;
      .clockpicker-display {
        @extend .white-text;
        .clockpicker-span-am-pm {
          @extend .white-text;
        }

      }
    }
    .picker__calendar-container{
      .clockpicker-plate {
        background-color: transparent;
        .clockpicker-tick {
          @extend .white-text;
          background-color: $timepicker-darktheme-clockpicker-plate-bg;
          &.active,
          &:hover {
            background-color: $timepicker-darktheme-clockpicker-plate-active-bg;
          }
        }
        .clockpicker-canvas line {
          stroke: $timepicker-darktheme-clockpicker-canvas-line-stroke;
        }
        .clockpicker-canvas-bearing {
          fill: $white-base;
        }
        .clockpicker-canvas-fg {
          fill: $timepicker-darktheme-clockpicker-canvas-fg-fill;
          &.active {
            fill: $timepicker-darktheme-clockpicker-canvas-active-fg-fill;
          }
        }
        .clockpicker-canvas-bg {
          fill: $timepicker-darktheme-clockpicker-canvas-bg-fill;
        }
        .clockpicker-canvas-bg-trans {
          fill: $timepicker-darktheme-clockpicker-canvas-bg-trans-fill;
        }
      }
    }
    .picker__footer{
      button {
        @extend .white-text;
      }
      .clockpicker-button:active {
        background-color: $timepicker-darktheme-clockpicker-button-active-bg;
      }
    }

  }

}

.card .card-body .card-text {
  color: $dark;
  font-size: 1rem;
  font-weight: 400;
}

.card {
	border-radius: .125rem;
  background: $silver;
  box-shadow: 0 0 0 0 !important;

  .card-body {
    padding: 1rem 1rem;
  }
}

@media (max-width: 767px) {
  .card {
    width: 100%;
  }
}
